// PeriodicTable.js
import React, { useState } from 'react';
import ElementCard from '../StrongComponents/ElementCard';

const PeriodicTable = () => {
  const [selectedElement, setSelectedElement] = useState(null);

  const elements = [
    { name: 'Hydrogen', symbol: 'H', atomicNumber: 1, group: 'nonmetal', description: 'Hydrogen is a chemical element with the symbol H and atomic number 1. It is the lightest and most abundant element in the universe.', atomicMass: '1.008 u', meltingPoint: '-259.16°C (14.01 K)', boilingPoint: '-252.87°C (20.28 K)', discoveryDate: '1766', discoveredBy: 'Henry Cavendish' },
    { name: 'Helium', symbol: 'He', atomicNumber: 2, group: 'noble', description: 'Helium is a chemical element with the symbol He and atomic number 2. It is a colorless, odorless, tasteless, non-toxic, inert, monatomic gas.', atomicMass: '4.0026 u', meltingPoint: '-272.20°C (0.95 K)', boilingPoint: '-268.93°C (4.22 K)', discoveryDate: '1868', discoveredBy: 'Pierre Janssen' },
    { name: 'Lithium', symbol: 'Li', atomicNumber: 3, group: 'alkali', description: 'Lithium is a chemical element with the symbol Li and atomic number 3. It is a soft, silvery-white alkali metal.', atomicMass: '6.94 u', meltingPoint: '180.50°C (453.65 K)', boilingPoint: '1342°C (1615 K)', discoveryDate: '1817', discoveredBy: 'Johan August Arfvedson' },
    { name: 'Beryllium', symbol: 'Be', atomicNumber: 4, group: 'alkaline', description: 'Beryllium is a chemical element with the symbol Be and atomic number 4. It is a hard, grayish metal naturally found in mineral rocks.', atomicMass: '9.0122 u', meltingPoint: '1287°C (1560 K)', boilingPoint: '2471°C (2744 K)', discoveryDate: '1798', discoveredBy: 'Louis Nicolas Vauquelin' },
    { name: 'Boron', symbol: 'B', atomicNumber: 5, group: 'metalloid', description: 'Boron is a chemical element with the symbol B and atomic number 5. It is a low-abundance element in the solar system and in the Earth\'s crust.', atomicMass: '10.81 u', meltingPoint: '2076°C (2349 K)', boilingPoint: '3927°C (4200 K)', discoveryDate: '1808', discoveredBy: 'Humphry Davy' },
    { name: 'Carbon', symbol: 'C', atomicNumber: 6, group: 'nonmetal', description: 'Carbon is a chemical element with the symbol C and atomic number 6. It is nonmetallic and tetravalent, making four electrons available to form covalent chemical bonds.', atomicMass: '12.011 u', meltingPoint: '3550°C (3823 K)', boilingPoint: '4827°C (5100 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Nitrogen', symbol: 'N', atomicNumber: 7, group: 'nonmetal', description: 'Nitrogen is a chemical element with the symbol N and atomic number 7. It is a common element in the universe, estimated at about seventh in total abundance in the Milky Way and the Solar System.', atomicMass: '14.007 u', meltingPoint: '-210.1°C (63.15 K)', boilingPoint: '-195.8°C (77.36 K)', discoveryDate: '1772', discoveredBy: 'Daniel Rutherford' },
    { name: 'Oxygen', symbol: 'O', atomicNumber: 8, group: 'nonmetal', description: 'Oxygen is a chemical element with the symbol O and atomic number 8. It is a member of the chalcogen group in the periodic table and is a highly reactive nonmetal and oxidizing agent that readily forms oxides with most elements as well as with other compounds.', atomicMass: '15.999 u', meltingPoint: '-218.79°C (54.36 K)', boilingPoint: '-182.96°C (90.19 K)', discoveryDate: '1774', discoveredBy: 'Joseph Priestley' },
    { name: 'Fluorine', symbol: 'F', atomicNumber: 9, group: 'halogen', description: 'Fluorine is a chemical element with the symbol F and atomic number 9. It is the lightest halogen and exists as a highly toxic pale yellow diatomic gas at standard conditions.', atomicMass: '18.998 u', meltingPoint: '-219.67°C (53.48 K)', boilingPoint: '-188.11°C (85.04 K)', discoveryDate: '1886', discoveredBy: 'Henri Moissan' },
    { name: 'Neon', symbol: 'Ne', atomicNumber: 10, group: 'noble', description: 'Neon is a chemical element with the symbol Ne and atomic number 10. It is a noble gas and was discovered (along with krypton and xenon) in 1898 as one of the three residual rare inert elements remaining in dry air, after nitrogen, oxygen, argon and carbon dioxide were removed.', atomicMass: '20.180 u', meltingPoint: '-248.59°C (24.56 K)', boilingPoint: '-246.08°C (27.07 K)', discoveryDate: '1898', discoveredBy: 'William Ramsay' },
    { name: 'Sodium', symbol: 'Na', atomicNumber: 11, group: 'alkali', description: 'Sodium is a chemical element with the symbol Na and atomic number 11. It is a soft, silvery-white, highly reactive metal.', atomicMass: '22.990 u', meltingPoint: '97.79°C (370.94 K)', boilingPoint: '883°C (1156 K)', discoveryDate: '1807', discoveredBy: 'Humphry Davy' },
    { name: 'Magnesium', symbol: 'Mg', atomicNumber: 12, group: 'alkaline', description: 'Magnesium is a chemical element with the symbol Mg and atomic number 12. It is a shiny gray solid which bears a close physical resemblance to the other five elements in the second column (group 2, or alkaline earth metals) of the periodic table.', atomicMass: '24.305 u', meltingPoint: '650°C (923 K)', boilingPoint: '1090°C (1363 K)', discoveryDate: '1755', discoveredBy: 'Joseph Black' },
    { name: 'Aluminium', symbol: 'Al', atomicNumber: 13, group: 'post-transition', description: 'Aluminium is a chemical element with the symbol Al and atomic number 13. It is a silvery-white, soft, nonmagnetic and ductile metal in the boron group.', atomicMass: '26.982 u', meltingPoint: '660.32°C (933.47 K)', boilingPoint: '2470°C (2743 K)', discoveryDate: '1825', discoveredBy: 'Hans Christian Ørsted' },
    { name: 'Silicon', symbol: 'Si', atomicNumber: 14, group: 'metalloid', description: 'Silicon is a chemical element with the symbol Si and atomic number 14. It is a hard, brittle crystalline solid with a blue-gray metallic luster, and is a tetravalent metalloid and semiconductor.', atomicMass: '28.085 u', meltingPoint: '1414°C (1687 K)', boilingPoint: '2900°C (3173 K)', discoveryDate: '1823', discoveredBy: 'Jöns Jacob Berzelius' },
    { name: 'Phosphorus', symbol: 'P', atomicNumber: 15, group: 'nonmetal', description: 'Phosphorus is a chemical element with the symbol P and atomic number 15. Elemental phosphorus exists in two major forms, white phosphorus and red phosphorus, but because it is highly reactive, phosphorus is never found as a free element on Earth.', atomicMass: '30.974 u', meltingPoint: '44.15°C (317.30 K)', boilingPoint: '280.5°C (553.7 K)', discoveryDate: '1669', discoveredBy: 'Hennig Brand' },
    { name: 'Sulfur', symbol: 'S', atomicNumber: 16, group: 'nonmetal', description: 'Sulfur is a chemical element with the symbol S and atomic number 16. It is abundant, multivalent and nonmetallic. Under normal conditions, sulfur atoms form cyclic octatomic molecules with a chemical formula S8.', atomicMass: '32.06 u', meltingPoint: '115.21°C (388.36 K)', boilingPoint: '444.6°C (717.8 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Chlorine', symbol: 'Cl', atomicNumber: 17, group: 'halogen', description: 'Chlorine is a chemical element with the symbol Cl and atomic number 17. It is the second-lightest of the halogens and is located between fluorine and bromine in the periodic table and its properties are mostly intermediate between them.', atomicMass: '35.45 u', meltingPoint: '-101.5°C (171.6 K)', boilingPoint: '-34.04°C (239.11 K)', discoveryDate: '1774', discoveredBy: 'Carl Wilhelm Scheele' },
    { name: 'Argon', symbol: 'Ar', atomicNumber: 18, group: 'noble', description: 'Argon is a chemical element with the symbol Ar and atomic number 18. It is in group 18 of the periodic table and is a noble gas.', atomicMass: '39.948 u', meltingPoint: '-189.34°C (83.80 K)', boilingPoint: '-185.84°C (87.30 K)', discoveryDate: '1894', discoveredBy: 'Lord Rayleigh' },
    { name: 'Potassium', symbol: 'K', atomicNumber: 19, group: 'alkali', description: 'Potassium is a chemical element with the symbol K and atomic number 19. It was first isolated from potash, the ashes of plants, from which its name derives.', atomicMass: '39.10 u', meltingPoint: '63.5°C (336.6 K)', boilingPoint: '759°C (1032 K)', discoveryDate: '1807', discoveredBy: 'Humphry Davy' },
    { name: 'Calcium', symbol: 'Ca', atomicNumber: 20, group: 'alkaline', description: 'Calcium is a chemical element with the symbol Ca and atomic number 20. It is an alkaline earth metal and is the fifth most abundant element in Earth\'s crust.', atomicMass: '40.08 u', meltingPoint: '842°C (1115 K)', boilingPoint: '1484°C (1757 K)', discoveryDate: '1808', discoveredBy: 'Humphry Davy' },
    { name: 'Scandium', symbol: 'Sc', atomicNumber: 21, group: 'transition', description: 'Scandium is a chemical element with the symbol Sc and atomic number 21. A silvery-white metallic d-block element, it has historically been classified as a rare-earth element, together with yttrium and the lanthanides.', atomicMass: '44.96 u', meltingPoint: '1541°C (1814 K)', boilingPoint: '2836°C (3109 K)', discoveryDate: '1879', discoveredBy: 'Lars Fredrik Nilson' },
    { name: 'Titanium', symbol: 'Ti', atomicNumber: 22, group: 'transition', description: 'Titanium is a chemical element with the symbol Ti and atomic number 22. It is a lustrous transition metal with a silver color, low density, and high strength.', atomicMass: '47.87 u', meltingPoint: '1668°C (1941 K)', boilingPoint: '3287°C (3560 K)', discoveryDate: '1791', discoveredBy: 'William Gregor' },
    { name: 'Vanadium', symbol: 'V', atomicNumber: 23, group: 'transition', description: 'Vanadium is a chemical element with the symbol V and atomic number 23. It is a hard, silvery-grey, ductile, and malleable transition metal.', atomicMass: '50.94 u', meltingPoint: '1910°C (2183 K)', boilingPoint: '3407°C (3680 K)', discoveryDate: '1801', discoveredBy: 'Andrés Manuel del Río' },
    { name: 'Chromium', symbol: 'Cr', atomicNumber: 24, group: 'transition', description: 'Chromium is a chemical element with the symbol Cr and atomic number 24. It is the first element in group 6.', atomicMass: '51.996 u', meltingPoint: '1907°C (2180 K)', boilingPoint: '2671°C (2944 K)', discoveryDate: '1797', discoveredBy: 'Louis Nicolas Vauquelin' },
    { name: 'Manganese', symbol: 'Mn', atomicNumber: 25, group: 'transition', description: 'Manganese is a chemical element with the symbol Mn and atomic number 25. It is not found as a free element in nature; it is often found in minerals in combination with iron.', atomicMass: '54.94 u', meltingPoint: '1246°C (1519 K)', boilingPoint: '2061°C (2334 K)', discoveryDate: '1774', discoveredBy: 'Johan Gottlieb Gahn' },
    { name: 'Iron', symbol: 'Fe', atomicNumber: 26, group: 'transition', description: 'Iron is a chemical element with the symbol Fe and atomic number 26. It is a metal that belongs to the first transition series and group 8 of the periodic table.', atomicMass: '55.84 u', meltingPoint: '1538°C (1811 K)', boilingPoint: '2862°C (3135 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Cobalt', symbol: 'Co', atomicNumber: 27, group: 'transition', description: 'Cobalt is a chemical element with the symbol Co and atomic number 27. Like nickel, cobalt is found in the Earth\'s crust only in chemically combined form, save for small deposits found in alloys of natural meteoric iron.', atomicMass: '58.93 u', meltingPoint: '1495°C (1768 K)', boilingPoint: '2927°C (3200 K)', discoveryDate: '1735', discoveredBy: 'Georg Brandt' },
    { name: 'Nickel', symbol: 'Ni', atomicNumber: 28, group: 'transition', description: 'Nickel is a chemical element with the symbol Ni and atomic number 28. It is a silvery-white lustrous metal with a slight golden tinge.', atomicMass: '58.69 u', meltingPoint: '1455°C (1728 K)', boilingPoint: '2730°C (3003 K)', discoveryDate: '1751', discoveredBy: 'Axel Fredrik Cronstedt' },
    { name: 'Copper', symbol: 'Cu', atomicNumber: 29, group: 'transition', description: 'Copper is a chemical element with the symbol Cu and atomic number 29. It is a soft, malleable, and ductile metal with very high thermal and electrical conductivity.', atomicMass: '63.55 u', meltingPoint: '1084.62°C (1357.77 K)', boilingPoint: '2562°C (2835 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Zinc', symbol: 'Zn', atomicNumber: 30, group: 'transition', description: 'Zinc is a chemical element with the symbol Zn and atomic number 30. It is the first element in group 12 (IIB) of the periodic table.', atomicMass: '65.38 u', meltingPoint: '419.53°C (692.68 K)', boilingPoint: '907°C (1180 K)', discoveryDate: '1746', discoveredBy: 'Andreas Marggraf' },
    { name: 'Gallium', symbol: 'Ga', atomicNumber: 31, group: 'post-transition', description: 'Gallium is a chemical element with the symbol Ga and atomic number 31. It is a soft, silvery metal, and elemental gallium is a brittle solid at low temperatures.', atomicMass: '69.72 u', meltingPoint: '29.76°C (302.91 K)', boilingPoint: '2204°C (2477 K)', discoveryDate: '1875', discoveredBy: 'Lecoq de Boisbaudran' },
    { name: 'Germanium', symbol: 'Ge', atomicNumber: 32, group: 'metalloid', description: 'Germanium is a chemical element with the symbol Ge and atomic number 32. It is a lustrous, hard-brittle, grayish-white metalloid in the carbon group.', atomicMass: '72.63 u', meltingPoint: '938.25°C (1211.4 K)', boilingPoint: '2833°C (3106 K)', discoveryDate: '1886', discoveredBy: 'Clemens Winkler' },
    { name: 'Arsenic', symbol: 'As', atomicNumber: 33, group: 'metalloid', description: 'Arsenic is a chemical element with the symbol As and atomic number 33. It occurs in many minerals, usually in combination with sulfur and metals, and also as a pure elemental crystal.', atomicMass: '74.92 u', meltingPoint: '817°C (1090 K)', boilingPoint: '613°C (886 K)', discoveryDate: '1250', discoveredBy: 'Albertus Magnus' },
    { name: 'Selenium', symbol: 'Se', atomicNumber: 34, group: 'nonmetal', description: 'Selenium is a chemical element with the symbol Se and atomic number 34. It is a nonmetal (more rarely considered a metalloid) with properties that are intermediate between the elements above and below in the periodic table, sulfur and tellurium, and also has similarities to arsenic.', atomicMass: '78.96 u', meltingPoint: '221°C (494 K)', boilingPoint: '685°C (958 K)', discoveryDate: '1817', discoveredBy: 'Jöns Jacob Berzelius' },
    { name: 'Bromine', symbol: 'Br', atomicNumber: 35, group: 'halogen', description: 'Bromine is a chemical element with the symbol Br and atomic number 35. It is the third-lightest halogen and is a fuming red-brown liquid at room temperature that evaporates readily to form a similarly coloured vapour.', atomicMass: '79.90 u', meltingPoint: '-7.2°C (265.9 K)', boilingPoint: '58.8°C (332 K)', discoveryDate: '1826', discoveredBy: 'Antoine Jérôme Balard' },
    { name: 'Krypton', symbol: 'Kr', atomicNumber: 36, group: 'noble', description: 'Krypton is a chemical element with the symbol Kr and atomic number 36. It is a member of group 18 (noble gases) elements.', atomicMass: '83.80 u', meltingPoint: '-157.36°C (115.79 K)', boilingPoint: '-153.22°C (119.93 K)', discoveryDate: '1898', discoveredBy: 'William Ramsay' },
    { name: 'Rubidium', symbol: 'Rb', atomicNumber: 37, group: 'alkali', description: 'Rubidium is a chemical element with the symbol Rb and atomic number 37. It is a very soft, silvery-white metal in the alkali metal group.', atomicMass: '85.47 u', meltingPoint: '39.31°C (312.46 K)', boilingPoint: '688°C (961 K)', discoveryDate: '1861', discoveredBy: 'Robert Bunsen' },
    { name: 'Strontium', symbol: 'Sr', atomicNumber: 38, group: 'alkaline', description: 'Strontium is a chemical element with the symbol Sr and atomic number 38. An alkaline earth metal, strontium is a soft silver-white yellowish metallic element that is highly reactive chemically.', atomicMass: '87.62 u', meltingPoint: '777°C (1050 K)', boilingPoint: '1382°C (1655 K)', discoveryDate: '1790', discoveredBy: 'William Cruickshank' },
    { name: 'Yttrium', symbol: 'Y', atomicNumber: 39, group: 'transition', description: 'Yttrium is a chemical element with the symbol Y and atomic number 39. It is a silvery-metallic transition metal chemically similar to the lanthanides and it has often been classified as a rare-earth element.', atomicMass: '88.91 u', meltingPoint: '1526°C (1799 K)', boilingPoint: '3338°C (3611 K)', discoveryDate: '1794', discoveredBy: 'Johan Gadolin' },
    { name: 'Zirconium', symbol: 'Zr', atomicNumber: 40, group: 'transition', description: 'Zirconium is a chemical element with the symbol Zr and atomic number 40. The name zirconium is taken from the name of the mineral zircon, the most important source of zirconium.', atomicMass: '91.22 u', meltingPoint: '1855°C (2128 K)', boilingPoint: '4409°C (4682 K)', discoveryDate: '1789', discoveredBy: 'Martin Heinrich Klaproth' },
    { name: 'Niobium', symbol: 'Nb', atomicNumber: 41, group: 'transition', description: 'Niobium is a chemical element with the symbol Nb and atomic number 41. It is a soft, grey, crystalline, ductile transition metal, often found in the minerals pyrochlore and columbite, hence the former name columbium.', atomicMass: '92.91 u', meltingPoint: '2468°C (2741 K)', boilingPoint: '4744°C (5017 K)', discoveryDate: '1801', discoveredBy: 'Charles Hatchett' },
    { name: 'Molybdenum', symbol: 'Mo', atomicNumber: 42, group: 'transition', description: 'Molybdenum is a chemical element with the symbol Mo and atomic number 42. The name is from Neo-Latin molybdaenum, from Ancient Greek Μόλυβδος molybdos, meaning lead, since its ores were confused with lead ores.', atomicMass: '95.95 u', meltingPoint: '2623°C (2896 K)', boilingPoint: '4639°C (4912 K)', discoveryDate: '1778', discoveredBy: 'Carl Wilhelm Scheele' },
    { name: 'Technetium', symbol: 'Tc', atomicNumber: 43, group: 'transition', description: 'Technetium is a chemical element with the symbol Tc and atomic number 43. It is the lightest element whose isotopes are all radioactive; none are stable.', atomicMass: '(98) u', meltingPoint: '2157°C (2430 K)', boilingPoint: '4265°C (4538 K)', discoveryDate: '1937', discoveredBy: 'Carlo Perrier' },
    { name: 'Ruthenium', symbol: 'Ru', atomicNumber: 44, group: 'transition', description: 'Ruthenium is a chemical element with the symbol Ru and atomic number 44. It is a rare transition metal belonging to the platinum group of the periodic table.', atomicMass: '101.07 u', meltingPoint: '2334°C (2607 K)', boilingPoint: '4150°C (4423 K)', discoveryDate: '1844', discoveredBy: 'Karl Ernst Claus' },
    { name: 'Rhodium', symbol: 'Rh', atomicNumber: 45, group: 'transition', description: 'Rhodium is a chemical element with the symbol Rh and atomic number 45. It is a rare, silvery-white, hard, corrosion-resistant, and chemically inert transition metal.', atomicMass: '102.91 u', meltingPoint: '1964°C (2237 K)', boilingPoint: '3695°C (3968 K)', discoveryDate: '1803', discoveredBy: 'William Hyde Wollaston' },
    { name: 'Palladium', symbol: 'Pd', atomicNumber: 46, group: 'transition', description: 'Palladium is a chemical element with the symbol Pd and atomic number 46. It is a rare and lustrous silvery-white metal discovered in 1803 by the English chemist William Hyde Wollaston.', atomicMass: '106.42 u', meltingPoint: '1554.9°C (1828.1 K)', boilingPoint: '2963°C (3236 K)', discoveryDate: '1803', discoveredBy: 'William Hyde Wollaston' },
    { name: 'Silver', symbol: 'Ag', atomicNumber: 47, group: 'transition', description: 'Silver is a chemical element with the symbol Ag and atomic number 47. A soft, white, lustrous transition metal, it exhibits the highest electrical conductivity, thermal conductivity, and reflectivity of any metal.', atomicMass: '107.87 u', meltingPoint: '961.78°C (1234.93 K)', boilingPoint: '2162°C (2435 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Cadmium', symbol: 'Cd', atomicNumber: 48, group: 'transition', description: 'Cadmium is a chemical element with the symbol Cd and atomic number 48. This soft, bluish-white metal is chemically similar to the two other stable metals in group 12, zinc and mercury.', atomicMass: '112.41 u', meltingPoint: '321.07°C (594.22 K)', boilingPoint: '767°C (1040 K)', discoveryDate: '1817', discoveredBy: 'Friedrich Stromeyer' },
    { name: 'Indium', symbol: 'In', atomicNumber: 49, group: 'post-transition', description: 'Indium is a chemical element with the symbol In and atomic number 49. It is a post-transition metal that makes up 0.21 parts per million of the Earth\'s crust.', atomicMass: '114.82 u', meltingPoint: '156.6°C (429.8 K)', boilingPoint: '2072°C (2345 K)', discoveryDate: '1863', discoveredBy: 'Ferdinand Reich' },
    { name: 'Tin', symbol: 'Sn', atomicNumber: 50, group: 'post-transition', description: 'Tin is a chemical element with the symbol Sn and atomic number 50. Tin is a silvery metal that characteristically has a faint yellow hue.', atomicMass: '118.71 u', meltingPoint: '231.93°C (505.08 K)', boilingPoint: '2602°C (2875 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Antimony', symbol: 'Sb', atomicNumber: 51, group: 'metalloid', description: 'Antimony is a chemical element with the symbol Sb and atomic number 51. A lustrous gray metalloid, it is found in nature mainly as the sulfide mineral stibnite (Sb2S3).', atomicMass: '121.76 u', meltingPoint: '630.63°C (903.78 K)', boilingPoint: '1587°C (1860 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Tellurium', symbol: 'Te', atomicNumber: 52, group: 'metalloid', description: 'Tellurium is a chemical element with the symbol Te and atomic number 52. It is a brittle, mildly toxic, rare, silver-white metalloid.', atomicMass: '127.60 u', meltingPoint: '449.51°C (722.66 K)', boilingPoint: '988°C (1261 K)', discoveryDate: '1782', discoveredBy: 'Franz-Joseph Müller von Reichenstein' },
    { name: 'Iodine', symbol: 'I', atomicNumber: 53, group: 'halogen', description: 'Iodine is a chemical element with the symbol I and atomic number 53. It is the heaviest of the stable halogens and exists as a lustrous, purple-black nonmetallic solid at standard conditions.', atomicMass: '126.90 u', meltingPoint: '113.7°C (386.9 K)', boilingPoint: '184.3°C (457.5 K)', discoveryDate: '1811', discoveredBy: 'Bernard Courtois' },
    { name: 'Xenon', symbol: 'Xe', atomicNumber: 54, group: 'noble', description: 'Xenon is a chemical element with the symbol Xe and atomic number 54. It is a colorless, dense, odorless noble gas found in the Earth\'s atmosphere in trace amounts.', atomicMass: '131.29 u', meltingPoint: '-111.75°C (161.40 K)', boilingPoint: '-108.13°C (165.02 K)', discoveryDate: '1898', discoveredBy: 'William Ramsay' },
    { name: 'Cesium', symbol: 'Cs', atomicNumber: 55, group: 'alkali', description: 'Cesium is a chemical element with the symbol Cs and atomic number 55. It is a soft, silvery-golden alkali metal with a melting point of 28.5°C (83.3°F), which makes it one of only five elemental metals that are liquid at or near room temperature.', atomicMass: '132.91 u', meltingPoint: '28.44°C (301.59 K)', boilingPoint: '671°C (944 K)', discoveryDate: '1860', discoveredBy: 'Robert Bunsen' },
    { name: 'Barium', symbol: 'Ba', atomicNumber: 56, group: 'alkaline', description: 'Barium is a chemical element with the symbol Ba and atomic number 56. It is a soft, silvery alkaline earth metal.', atomicMass: '137.33 u', meltingPoint: '727°C (1000 K)', boilingPoint: '1845°C (2118 K)', discoveryDate: '1808', discoveredBy: 'Humphry Davy' },
    { name: 'Lanthanum', symbol: 'La', atomicNumber: 57, group: 'lanthanide', description: 'Lanthanum is a chemical element with the symbol La and atomic number 57. It is a soft, ductile, silvery-white metal that tarnishes rapidly when exposed to air and is soft enough to be cut with a knife.', atomicMass: '138.91 u', meltingPoint: '920°C (1193 K)', boilingPoint: '3464°C (3737 K)', discoveryDate: '1839', discoveredBy: 'Carl Gustaf Mosander' },
    { name: 'Cerium', symbol: 'Ce', atomicNumber: 58, group: 'lanthanide', description: 'Cerium is a chemical element with the symbol Ce and atomic number 58. Cerium is a soft, silvery, ductile metal that easily oxidizes in air.', atomicMass: '140.12 u', meltingPoint: '795°C (1068 K)', boilingPoint: '3443°C (3716 K)', discoveryDate: '1803', discoveredBy: 'Jöns Jacob Berzelius' },
    { name: 'Praseodymium', symbol: 'Pr', atomicNumber: 59, group: 'lanthanide', description: 'Praseodymium is a chemical element with the symbol Pr and atomic number 59. It is the third member of the lanthanide series and is traditionally considered to be one of the rare-earth metals.', atomicMass: '140.90 u', meltingPoint: '931°C (1204 K)', boilingPoint: '3290°C (3563 K)', discoveryDate: '1885', discoveredBy: 'Carl Auer von Welsbach' },
    { name: 'Neodymium', symbol: 'Nd', atomicNumber: 60, group: 'lanthanide', description: 'Neodymium is a chemical element with the symbol Nd and atomic number 60. It is a soft silvery metal that tarnishes in air.', atomicMass: '144.24 u', meltingPoint: '1016°C (1289 K)', boilingPoint: '3074°C (3347 K)', discoveryDate: '1885', discoveredBy: 'Carl Auer von Welsbach' },
    { name: 'Promethium', symbol: 'Pm', atomicNumber: 61, group: 'lanthanide', description: 'Promethium is a chemical element with the symbol Pm and atomic number 61. All of its isotopes are radioactive; it is extremely rare, with only about 500–600 grams naturally occurring in Earth\'s crust at any given time.', atomicMass: '(145) u', meltingPoint: '1042°C (1315 K)', boilingPoint: '3000°C (3273 K)', discoveryDate: '1945', discoveredBy: 'Jacob A. Marinsky' },
    { name: 'Samarium', symbol: 'Sm', atomicNumber: 62, group: 'lanthanide', description: 'Samarium is a chemical element with the symbol Sm and atomic number 62. It is a moderately hard silvery metal that slowly oxidizes in air.', atomicMass: '150.36 u', meltingPoint: '1072°C (1345 K)', boilingPoint: '1794°C (2067 K)', discoveryDate: '1879', discoveredBy: 'Lecoq de Boisbaudran' },
    { name: 'Europium', symbol: 'Eu', atomicNumber: 63, group: 'lanthanide', description: 'Europium is a chemical element with the symbol Eu and atomic number 63. It is a moderately hard silvery metal which readily oxidizes in air and water.', atomicMass: '151.96 u', meltingPoint: '826°C (1099 K)', boilingPoint: '1529°C (1802 K)', discoveryDate: '1896', discoveredBy: 'Eugène-Anatole Demarçay' },
    { name: 'Gadolinium', symbol: 'Gd', atomicNumber: 64, group: 'lanthanide', description: 'Gadolinium is a chemical element with the symbol Gd and atomic number 64. It is a silvery-white metal when oxidation is removed.', atomicMass: '157.25 u', meltingPoint: '1312°C (1585 K)', boilingPoint: '3273°C (3546 K)', discoveryDate: '1880', discoveredBy: 'Jean Charles Galissard de Marignac' },
    { name: 'Terbium', symbol: 'Tb', atomicNumber: 65, group: 'lanthanide', description: 'Terbium is a chemical element with the symbol Tb and atomic number 65. It is a silvery-white, rare earth metal that is malleable, ductile, and soft enough to be cut with a knife.', atomicMass: '158.92 u', meltingPoint: '1356°C (1629 K)', boilingPoint: '3230°C (3503 K)', discoveryDate: '1843', discoveredBy: 'Carl Gustaf Mosander' },
    { name: 'Dysprosium', symbol: 'Dy', atomicNumber: 66, group: 'lanthanide', description: 'Dysprosium is a chemical element with the symbol Dy and atomic number 66. It is a rare earth element that has a metallic, bright silver luster.', atomicMass: '162.50 u', meltingPoint: '1412°C (1685 K)', boilingPoint: '2562°C (2835 K)', discoveryDate: '1886', discoveredBy: 'Paul Émile Lecoq de Boisbaudran' },
    { name: 'Holmium', symbol: 'Ho', atomicNumber: 67, group: 'lanthanide', description: 'Holmium is a chemical element with the symbol Ho and atomic number 67. Part of the lanthanide series, holmium is a rare-earth element.', atomicMass: '164.93 u', meltingPoint: '1474°C (1747 K)', boilingPoint: '2700°C (2973 K)', discoveryDate: '1878', discoveredBy: 'Marc Delafontaine' },
    { name: 'Erbium', symbol: 'Er', atomicNumber: 68, group: 'lanthanide', description: 'Erbium is a chemical element with the symbol Er and atomic number 68. A silvery-white solid metal when artificially isolated, natural erbium is always found in chemical combination with other elements.', atomicMass: '167.26 u', meltingPoint: '1497°C (1770 K)', boilingPoint: '2868°C (3141 K)', discoveryDate: '1842', discoveredBy: 'Carl Gustaf Mosander' },
    { name: 'Thulium', symbol: 'Tm', atomicNumber: 69, group: 'lanthanide', description: 'Thulium is a chemical element with the symbol Tm and atomic number 69. It is the thirteenth and third-last element in the lanthanide series.', atomicMass: '168.93 u', meltingPoint: '1545°C (1818 K)', boilingPoint: '1950°C (2223 K)', discoveryDate: '1879', discoveredBy: 'Per Teodor Cleve' },
    { name: 'Ytterbium', symbol: 'Yb', atomicNumber: 70, group: 'lanthanide', description: 'Ytterbium is a chemical element with the symbol Yb and atomic number 70. It is the fourteenth and penultimate element in the lanthanide series.', atomicMass: '173.04 u', meltingPoint: '824°C (1097 K)', boilingPoint: '1196°C (1469 K)', discoveryDate: '1878', discoveredBy: 'Jean Charles Galissard de Marignac' },
    { name: 'Lutetium', symbol: 'Lu', atomicNumber: 71, group: 'lanthanide', description: 'Lutetium is a chemical element with the symbol Lu and atomic number 71. It is a silvery-white metal and the last element in the lanthanide series.', atomicMass: '174.97 u', meltingPoint: '1652°C (1925 K)', boilingPoint: '3402°C (3675 K)', discoveryDate: '1907', discoveredBy: 'Georges Urbain' },
    { name: 'Hafnium', symbol: 'Hf', atomicNumber: 72, group: 'transition', description: 'Hafnium is a chemical element with the symbol Hf and atomic number 72. It is a lustrous, silvery-gray, tetravalent transition metal.', atomicMass: '178.49 u', meltingPoint: '2233°C (2506 K)', boilingPoint: '4602°C (4875 K)', discoveryDate: '1923', discoveredBy: 'Dirk Coster' },
    { name: 'Tantalum', symbol: 'Ta', atomicNumber: 73, group: 'transition', description: 'Tantalum is a chemical element with the symbol Ta and atomic number 73. Previously known as tantalium, its name comes from Tantalus, a villain from Greek mythology.', atomicMass: '180.95 u', meltingPoint: '3017°C (3290 K)', boilingPoint: '5458°C (5731 K)', discoveryDate: '1802', discoveredBy: 'Anders Gustaf Ekeberg' },
    { name: 'Tungsten', symbol: 'W', atomicNumber: 74, group: 'transition', description: 'Tungsten is a chemical element with the symbol W and atomic number 74. Tungsten is a rare metal found naturally on Earth almost exclusively as compounds with other elements.', atomicMass: '183.84 u', meltingPoint: '3422°C (3695 K)', boilingPoint: '5555°C (5828 K)', discoveryDate: '1781', discoveredBy: 'Carl Wilhelm Scheele' },
    { name: 'Rhenium', symbol: 'Re', atomicNumber: 75, group: 'transition', description: 'Rhenium is a chemical element with the symbol Re and atomic number 75. It is a silvery-white, heavy, third-row transition metal in group 7 of the periodic table.', atomicMass: '186.21 u', meltingPoint: '3186°C (3459 K)', boilingPoint: '5596°C (5869 K)', discoveryDate: '1925', discoveredBy: 'Masataka Ogawa' },
    { name: 'Osmium', symbol: 'Os', atomicNumber: 76, group: 'transition', description: 'Osmium is a chemical element with the symbol Os and atomic number 76. It is a hard, brittle, bluish-white transition metal in the platinum group, found as a trace element in alloys, mostly in platinum ores.', atomicMass: '190.23 u', meltingPoint: '3033°C (3306 K)', boilingPoint: '5012°C (5285 K)', discoveryDate: '1803', discoveredBy: 'Smithson Tennant' },
    { name: 'Iridium', symbol: 'Ir', atomicNumber: 77, group: 'transition', description: 'Iridium is a chemical element with the symbol Ir and atomic number 77. A very hard, brittle, silvery-white transition metal of the platinum group, it is considered to be the second-densest metal with a density of 22.56 g/cm3 as defined by experimental X-ray crystallography.', atomicMass: '192.22 u', meltingPoint: '2466°C (2739 K)', boilingPoint: '4428°C (4701 K)', discoveryDate: '1803', discoveredBy: 'Smithson Tennant' },
    { name: 'Platinum', symbol: 'Pt', atomicNumber: 78, group: 'transition', description: 'Platinum is a chemical element with the symbol Pt and atomic number 78. It is a dense, malleable, ductile, highly unreactive, precious, silverish-white transition metal.', atomicMass: '195.08 u', meltingPoint: '1768.3°C (2041.5 K)', boilingPoint: '3825°C (4098 K)', discoveryDate: '1735', discoveredBy: 'Antonio de Ulloa' },
    { name: 'Gold', symbol: 'Au', atomicNumber: 79, group: 'transition', description: 'Gold is a chemical element with the symbol Au and atomic number 79. It is a bright, slightly orange-yellow, dense, soft, malleable, and ductile metal in a pure form.', atomicMass: '196.97 u', meltingPoint: '1064.18°C (1337.33 K)', boilingPoint: '2856°C (3129 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Mercury', symbol: 'Hg', atomicNumber: 80, group: 'transition', description: 'Mercury is a chemical element with the symbol Hg and atomic number 80. It is commonly known as quicksilver and was formerly named hydrargyrum.', atomicMass: '200.59 u', meltingPoint: '-38.83°C (234.32 K)', boilingPoint: '356.73°C (629.88 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Thallium', symbol: 'Tl', atomicNumber: 81, group: 'post-transition', description: 'Thallium is a chemical element with the symbol Tl and atomic number 81. It is a gray post-transition metal that is not found free in nature.', atomicMass: '204.38 u', meltingPoint: '304°C (577 K)', boilingPoint: '1473°C (1746 K)', discoveryDate: '1861', discoveredBy: 'William Crookes' },
    { name: 'Lead', symbol: 'Pb', atomicNumber: 82, group: 'post-transition', description: 'Lead is a chemical element with the symbol Pb and atomic number 82. It is a heavy metal that is denser than most common materials.', atomicMass: '207.2 u', meltingPoint: '327.46°C (600.61 K)', boilingPoint: '1749°C (2022 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Bismuth', symbol: 'Bi', atomicNumber: 83, group: 'post-transition', description: 'Bismuth is a chemical element with the symbol Bi and atomic number 83. It is a pentavalent post-transition metal and one of the pnictogens, with chemical properties resembling its lighter group 15 siblings arsenic and antimony.', atomicMass: '208.98 u', meltingPoint: '271.3°C (544.5 K)', boilingPoint: '1564°C (1837 K)', discoveryDate: 'Ancient times', discoveredBy: 'Unknown' },
    { name: 'Polonium', symbol: 'Po', atomicNumber: 84, group: 'metalloid', description: 'Polonium is a chemical element with the symbol Po and atomic number 84. It is a radioactive element and has been studied for possible use in heating spacecraft.', atomicMass: '(209) u', meltingPoint: '254°C (527 K)', boilingPoint: '962°C (1235 K)', discoveryDate: '1898', discoveredBy: 'Marie Curie' },
    { name: 'Astatine', symbol: 'At', atomicNumber: 85, group: 'halogen', description: 'Astatine is a chemical element with the symbol At and atomic number 85. It is the rarest naturally occurring element in the Earth\'s crust, occurring only as the decay product of various heavier elements.', atomicMass: '(210) u', meltingPoint: '302°C (575 K)', boilingPoint: '337°C (610 K)', discoveryDate: '1940', discoveredBy: 'Dale R. Corson' },
    { name: 'Radon', symbol: 'Rn', atomicNumber: 86, group: 'noble', description: 'Radon is a chemical element with the symbol Rn and atomic number 86. It is a radioactive, colorless, odorless, tasteless noble gas.', atomicMass: '(222) u', meltingPoint: '-71°C (202 K)', boilingPoint: '-61.7°C (211.4 K)', discoveryDate: '1898', discoveredBy: 'Friedrich Ernst Dorn' },
    { name: 'Francium', symbol: 'Fr', atomicNumber: 87, group: 'alkali', description: 'Francium is a chemical element with the symbol Fr and atomic number 87. It is the second rarest naturally occurring element after astatine.', atomicMass: '(223) u', meltingPoint: '27°C (300 K)', boilingPoint: '677°C (950 K)', discoveryDate: '1939', discoveredBy: 'Marguerite Perey' },
    { name: 'Radium', symbol: 'Ra', atomicNumber: 88, group: 'alkaline', description: 'Radium is a chemical element with the symbol Ra and atomic number 88. It is the sixth element in group 2 of the periodic table, also known as the alkaline earth metals.', atomicMass: '(226) u', meltingPoint: '700°C (973 K)', boilingPoint: '1737°C (2010 K)', discoveryDate: '1898', discoveredBy: 'Marie Curie' },
    { name: 'Actinium', symbol: 'Ac', atomicNumber: 89, group: 'actinide', description: 'Actinium is a chemical element with the symbol Ac and atomic number 89. It was discovered in 1899 and was the first non-primordial radioactive element to be isolated.', atomicMass: '(227) u', meltingPoint: '1050°C (1323 K)', boilingPoint: '3198°C (3471 K)', discoveryDate: '1899', discoveredBy: 'André-Louis Debierne' },
    { name: 'Thorium', symbol: 'Th', atomicNumber: 90, group: 'actinide', description: 'Thorium is a weakly radioactive metallic chemical element with the symbol Th and atomic number 90. Thorium metal is silvery and tarnishes black when exposed to air, forming thorium dioxide.', atomicMass: '232.04 u', meltingPoint: '1750°C (2023 K)', boilingPoint: '4820°C (5093 K)', discoveryDate: '1828', discoveredBy: 'Jöns Jacob Berzelius' },
    { name: 'Protactinium', symbol: 'Pa', atomicNumber: 91, group: 'actinide', description: 'Protactinium is a chemical element with the symbol Pa and atomic number 91. It is a dense, silvery-gray actinide metal that reacts readily with oxygen, water vapor and inorganic acids.', atomicMass: '231.04 u', meltingPoint: '1568°C (1841 K)', boilingPoint: '4000°C (4273 K)', discoveryDate: '1913', discoveredBy: 'Kazimierz Fajans' },
    { name: 'Uranium', symbol: 'U', atomicNumber: 92, group: 'actinide', description: 'Uranium is a chemical element with the symbol U and atomic number 92. It is a silvery-grey metal in the actinide series of the periodic table.', atomicMass: '238.03 u', meltingPoint: '1135°C (1408 K)', boilingPoint: '4131°C (4404 K)', discoveryDate: '1789', discoveredBy: 'Martin Heinrich Klaproth' },
    { name: 'Neptunium', symbol: 'Np', atomicNumber: 93, group: 'actinide', description: 'Neptunium is a chemical element with the symbol Np and atomic number 93. It is a radioactive actinide metal.', atomicMass: '(237) u', meltingPoint: '640°C (913 K)', boilingPoint: '3902°C (4175 K)', discoveryDate: '1940', discoveredBy: 'Edwin McMillan' },
    { name: 'Plutonium', symbol: 'Pu', atomicNumber: 94, group: 'actinide', description: 'Plutonium is a radioactive chemical element with the symbol Pu and atomic number 94. It is an actinide metal of silvery-gray appearance that tarnishes when exposed to air, and forms a dull coating when oxidized.', atomicMass: '(244) u', meltingPoint: '639.4°C (912.5 K)', boilingPoint: '3235°C (3508 K)', discoveryDate: '1940', discoveredBy: 'Glenn T. Seaborg' },
    { name: 'Americium', symbol: 'Am', atomicNumber: 95, group: 'actinide', description: 'Americium is a synthetic radioactive chemical element with the symbol Am and atomic number 95. It is a metallic element that does not occur naturally.', atomicMass: '(243) u', meltingPoint: '1176°C (1449 K)', boilingPoint: '2607°C (2880 K)', discoveryDate: '1944', discoveredBy: 'Glenn T. Seaborg' },
    { name: 'Curium', symbol: 'Cm', atomicNumber: 96, group: 'actinide', description: 'Curium is a transuranic radioactive chemical element with the symbol Cm and atomic number 96. This element of the actinide series was named after Marie and Pierre Curie.', atomicMass: '(247) u', meltingPoint: '1340°C (1613 K)', boilingPoint: '3110°C (3383 K)', discoveryDate: '1944', discoveredBy: 'Glenn T. Seaborg' },
    { name: 'Berkelium', symbol: 'Bk', atomicNumber: 97, group: 'actinide', description: 'Berkelium is a transuranic radioactive chemical element with the symbol Bk and atomic number 97. It is a member of the actinide and transuranium element series.', atomicMass: '(247) u', meltingPoint: '986°C (1259 K)', boilingPoint: '2627°C (2900 K)', discoveryDate: '1949', discoveredBy: 'Stanley G. Thompson' },
    { name: 'Californium', symbol: 'Cf', atomicNumber: 98, group: 'actinide', description: 'Californium is a radioactive chemical element with the symbol Cf and atomic number 98. The element was first synthesized in 1950 at the Lawrence Berkeley National Laboratory, by bombarding curium with alpha particles (helium-4 ions).', atomicMass: '(251) u', meltingPoint: '900°C (1173 K)', boilingPoint: '1470°C (1743 K)', discoveryDate: '1950', discoveredBy: 'Stanley G. Thompson' },
    { name: 'Einsteinium', symbol: 'Es', atomicNumber: 99, group: 'actinide', description: 'Einsteinium is a synthetic element with the symbol Es and atomic number 99. It is the seventh transuranic element, and an actinide.', atomicMass: '(252) u', meltingPoint: '860°C (1133 K)', boilingPoint: '996°C (1269 K)', discoveryDate: '1952', discoveredBy: 'Albert Ghiorso' },
    { name: 'Fermium', symbol: 'Fm', atomicNumber: 100, group: 'actinide', description: 'Fermium is a synthetic element with the symbol Fm and atomic number 100. It is an actinide and the heaviest element that can be formed by neutron bombardment of lighter elements, and hence the last element that can be prepared in macroscopic quantities by this means.', atomicMass: '(257) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1952', discoveredBy: 'Albert Ghiorso' },
    { name: 'Mendelevium', symbol: 'Md', atomicNumber: 101, group: 'actinide', description: 'Mendelevium is a synthetic element with the symbol Md and atomic number 101. It is the ninth transuranic element, and is the third-to-last actinide.', atomicMass: '(258) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1955', discoveredBy: 'Albert Ghiorso' },
    { name: 'Nobelium', symbol: 'No', atomicNumber: 102, group: 'actinide', description: 'Nobelium is a synthetic element with the symbol No and atomic number 102. It is the tenth transuranic element and is the penultimate member of the actinide series.', atomicMass: '(259) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1958', discoveredBy: 'Albert Ghiorso' },
    { name: 'Lawrencium', symbol: 'Lr', atomicNumber: 103, group: 'actinide', description: 'Lawrencium is a synthetic chemical element with the symbol Lr and atomic number 103. It is named in honor of Ernest Lawrence, the inventor of the cyclotron, a device that was used to discover many artificial radioactive elements.', atomicMass: '(262) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1961', discoveredBy: 'Albert Ghiorso' },
    { name: 'Rutherfordium', symbol: 'Rf', atomicNumber: 104, group: 'transition', description: 'Rutherfordium is a synthetic chemical element with the symbol Rf and atomic number 104. It is an actinide and the first transactinide element.', atomicMass: '(267) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1964', discoveredBy: 'Georgy Flerov' },
    { name: 'Dubnium', symbol: 'Db', atomicNumber: 105, group: 'transition', description: 'Dubnium is a synthetic chemical element with the symbol Db and atomic number 105. It is an actinide and the second transactinide element.', atomicMass: '(270) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1967', discoveredBy: 'Georgy Flerov' },
    { name: 'Seaborgium', symbol: 'Sg', atomicNumber: 106, group: 'transition', description: 'Seaborgium is a synthetic chemical element with the symbol Sg and atomic number 106. It is an actinide and the third transactinide element.', atomicMass: '(271) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1974', discoveredBy: 'Albert Ghiorso' },
    { name: 'Bohrium', symbol: 'Bh', atomicNumber: 107, group: 'transition', description: 'Bohrium is a synthetic chemical element with the symbol Bh and atomic number 107. It is an actinide and the fourth transactinide element.', atomicMass: '(270) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1981', discoveredBy: 'Peter Armbruster' },
    { name: 'Hassium', symbol: 'Hs', atomicNumber: 108, group: 'transition', description: 'Hassium is a synthetic chemical element with the symbol Hs and atomic number 108. It is an actinide and the fifth transactinide element.', atomicMass: '(277) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1984', discoveredBy: 'Peter Armbruster' },
    { name: 'Meitnerium', symbol: 'Mt', atomicNumber: 109, group: 'transition', description: 'Meitnerium is a synthetic chemical element with the symbol Mt and atomic number 109. It is an actinide and the sixth transactinide element.', atomicMass: '(278) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1982', discoveredBy: 'Peter Armbruster' },
    { name: 'Darmstadtium', symbol: 'Ds', atomicNumber: 110, group: 'transition', description: 'Darmstadtium is a synthetic chemical element with the symbol Ds and atomic number 110. It is an actinide and the seventh transactinide element.', atomicMass: '(281) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1994', discoveredBy: 'Sigurd Hofmann' },
    { name: 'Roentgenium', symbol: 'Rg', atomicNumber: 111, group: 'transition', description: 'Roentgenium is a chemical element with the symbol Rg and atomic number 111. It is an actinide and the eighth transactinide element.', atomicMass: '(282) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1994', discoveredBy: 'Sigurd Hofmann' },
    { name: 'Copernicium', symbol: 'Cn', atomicNumber: 112, group: 'transition', description: 'Copernicium is a synthetic chemical element with the symbol Cn and atomic number 112. It is an actinide and the ninth transactinide element.', atomicMass: '(285) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1996', discoveredBy: 'Sigurd Hofmann' },
    { name: 'Nihonium', symbol: 'Nh', atomicNumber: 113, group: 'transition', description: 'Nihonium is a synthetic chemical element with the symbol Nh and atomic number 113. It is an actinide and the tenth transactinide element.', atomicMass: '(286) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '2003', discoveredBy: 'Ryoji Noyori' },
    { name: 'Flerovium', symbol: 'Fl', atomicNumber: 114, group: 'transition', description: 'Flerovium is a superheavy artificial chemical element with the symbol Fl and atomic number 114. It is an actinide and the eleventh transactinide element.', atomicMass: '(289) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '1998', discoveredBy: 'Yuri Oganessian' },
    { name: 'Moscovium', symbol: 'Mc', atomicNumber: 115, group: 'transition', description: 'Moscovium is a synthetic chemical element with the symbol Mc and atomic number 115. It is an actinide and the twelfth transactinide element.', atomicMass: '(290) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '2003', discoveredBy: 'Yuri Oganessian' },
    { name: 'Livermorium', symbol: 'Lv', atomicNumber: 116, group: 'transition', description: 'Livermorium is a synthetic chemical element with the symbol Lv and atomic number 116. It is an actinide and the thirteenth transactinide element.', atomicMass: '(293) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '2000', discoveredBy: 'Yuri Oganessian' },
    { name: 'Tennessine', symbol: 'Ts', atomicNumber: 117, group: 'transition', description: 'Tennessine is a synthetic chemical element with the symbol Ts and atomic number 117. It is an actinide and the fourteenth transactinide element.', atomicMass: '(294) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '2010', discoveredBy: 'Yuri Oganessian' },
    { name: 'Oganesson', symbol: 'Og', atomicNumber: 118, group: 'transition', description: 'Oganesson is a synthetic chemical element with the symbol Og and atomic number 118. It is an actinide and the fifteenth transactinide element.', atomicMass: '(294) u', meltingPoint: 'Unknown', boilingPoint: 'Unknown', discoveryDate: '2002', discoveredBy: 'Yuri Oganessian' }
  ];

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Periodic Table</h1>
      <div className="grid grid-cols-18 gap-2">
        {elements.map((element, index) => (
          <div
            key={index}
            className={`p-2 border rounded cursor-pointer ${getElementGroupStyle(element.group)}`}
            onClick={() => setSelectedElement(element)}
          >
            <div className="text-center">
              <div className="text-sm">{element.atomicNumber}</div>
              <div className="text-lg font-bold">{element.symbol}</div>
              <div className="text-sm">{element.name}</div>
            </div>
          </div>
        ))}
      </div>
      {selectedElement && <ElementCard element={selectedElement} onClose={() => setSelectedElement(null)} />}
    </div>
  );
};

const getElementGroupStyle = (group) => {
  switch (group) {
    case 'alkali':
      return 'bg-red-300';
    case 'alkaline':
      return 'bg-yellow-300';
    case 'transition':
      return 'bg-green-300';
    case 'post-transition':
      return 'bg-blue-300';
    case 'metalloid':
      return 'bg-purple-300';
    case 'nonmetal':
      return 'bg-gray-300';
    case 'noble':
      return 'bg-pink-300';
    case 'lanthanide':
      return 'bg-indigo-300';
    case 'actinide':
      return 'bg-teal-300';
    default:
      return 'bg-white';
  }
};

export default PeriodicTable;
